import React, { useEffect, useContext } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';
import AuthContext from '../context/AuthContext';
import createPhoneMask from '../helpers/createPhoneMask';
import phoneOperatorValidate from '../helpers/phoneOperatorValidate';
import FallbackInput from './FallbackInput';

const MaskedInput = dynamic(() => import('./MaskedInput'), {
  loading: () => <FallbackInput defaultValue="(0" />,
  ssr: false
});

const InputGroupPhone = ({ button }) => {
  const { t } = useTranslation();
  const {
    phone,
    setPhone,
    setError,
    wrongOperator,
    setWrongOperator,
    loading,
    authRef
  } = useContext(AuthContext);
  const targetElement = authRef && authRef.current;
  const placeholderChar = '\u2000';

  useEffect(() => () => {
    setWrongOperator(false);
    if (isIOS) clearAllBodyScrollLocks();
  }, []);

  const onKeyDown = e => {
    const event = e.nativeEvent;
    const key = event.keyCode || event.charCode;
    const isDefaultValue = event.target.value.length === 2;
    if (key === 13) {
      button.current.click();
    }
    if ((key === 8 || key === 46) && isDefaultValue) {
      event.preventDefault();
    }
  };

  const onKeyUp = e => {
    const event = e.nativeEvent;
    const key = event.keyCode || event.charCode;
    const isEmptyValue = event.target.value.length === 0;

    if ((key === 8 || key === 46) && isEmptyValue) {
      setPhone('(0');
    }
  };

  const onFocus = () => {
    if (isIOS) disableBodyScroll(targetElement);
  };

  const onBlur = () => {
    if (isIOS) enableBodyScroll(targetElement);
  };

  const checkOperator = purePhone => {
    if (purePhone.length < 3) {
      setError(0);
      if (wrongOperator) setWrongOperator(false);
      return;
    }

    if (phoneOperatorValidate(purePhone)) {
      setError(0);

      if (wrongOperator) setWrongOperator(false);
    } else {
      setError(2);
      setWrongOperator(true);
    }
  };

  const onChange = e => {
    const targetPhone = e.nativeEvent.target.value;
    const purePhone = targetPhone.replace(/\D+/g, '');
    const purePhoneLength = purePhone.length;

    setPhone(targetPhone);
    checkOperator(purePhone);

    if (purePhoneLength > 3 && purePhoneLength < 10 && !wrongOperator) {
      setError(0);
    }
  };

  return (
    <div className="input-group">
      <MaskedInput
        mask={createPhoneMask()}
        {...{
          placeholderChar,
          onChange,
          onKeyDown,
          onKeyUp,
          onFocus,
          onBlur
        }}
        id="phone-field"
        type="tel"
        value={phone}
        disabled={loading}
      />
      <span className="input-group__placeholder">{t('input_group_phone.placeholder')}</span>
    </div>
  );
};

InputGroupPhone.propTypes = {
  button: PropTypes.object.isRequired
};

export default InputGroupPhone;
