import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BackBtn from './BackBtn';

const Header = ({ title, titleClassName, backPath }) => (
  <div className="header">
    <BackBtn {...{ backPath }} />
    <div className={classNames('title', titleClassName)}>{title}</div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  backPath: PropTypes.string
};

export default Header;
