import React, { useRef, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { conformToMask } from 'react-text-mask';
import AuthContext from '../../context/AuthContext';
import InputGroupPhone from '../InputGroupPhone';
import Header from '../Header';
import Error from '../Error';
import requestSmsCode from '../../queries/requestSmsCode';
import phoneIsIncomplete from '../../helpers/phoneIsIncomplete';
import createPhoneMask from '../../helpers/createPhoneMask';

const onClick = (context, router) => {
  if (!phoneIsIncomplete(context)) {
    requestSmsCode(context, router);
  }
};

const LoginByPhonePage = () => {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  const context = useContext(AuthContext);
  const {
    attemptsExhausted,
    error,
    setError,
    wrongOperator,
    loading,
    predefinedPhone,
    setPhone,
    purePhone,
    isRequestSmsCodeSent
  } = context;
  const router = useRouter();

  useEffect(() => {
    if (error && !attemptsExhausted && !wrongOperator) setError(0);
    if (predefinedPhone) {
      const conformedPhoneNumber = conformToMask(
        predefinedPhone,
        createPhoneMask(),
        { guide: false }
      );

      setPhone(conformedPhoneNumber.conformedValue);
    }
  }, []);

  useEffect(() => {
    if (purePhone.length === 10 && predefinedPhone && !isRequestSmsCodeSent) {
      requestSmsCode(context, router);
    }
  }, [purePhone]);

  return (
    <div className={classNames('login-by-phone', { 'has-error': Boolean(error) }, { loading })}>
      <Header title={t('login_by_phone.title')} titleClassName="title--main" />
      {error && <Error error={error} />}
      <InputGroupPhone button={buttonRef} />
      <button
        type="button"
        className={classNames('btn', 'btn--default', { disabled: phoneIsIncomplete(context) })}
        onClick={() => onClick(context, router)}
        ref={buttonRef}
      >
        { loading ? <div className="loader" /> : t('login_by_phone.btn_get_sms')}
      </button>
    </div>
  );
};

export default LoginByPhonePage;
